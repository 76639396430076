import React from 'react';
import '../../components/AvilableHome.css';
import '../../components/Availability.css';
import { makeStyles } from '@material-ui/core/styles';
import './NavBar.css';
import banner22 from '../../images/Bannermob13.png';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import MuiAlert from '@material-ui/lab/Alert';
import PhoneIcon from '@material-ui/icons/Phone';
import Container from '@material-ui/core/Container';
import Zillow from '../../images/zillow.svg';
import Link from '@material-ui/core/Link';
import Footer from '../../components/Footer';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Avatar, Box, Paper, Rating, Stack, styled, useMediaQuery, useTheme, Typography, Button, Dialog, DialogTitle, IconButton, DialogContent, Divider } from '@mui/material';
import Image from '../../images/Deepti.png';
import { PropertyContext } from '../../context';
import Loading from '../Loading';
import { Close, Forum, Instagram, Mail, PhoneIphone } from '@mui/icons-material';
import kelly from '../../images/logoS.2.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  gridCenter: {
    alignItems: "center"
  },
  GridSpace: {
    padding: "0px 30px",
  },
  center: {
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0px 0px 0px",
      textAlign: "center"
    }

  },
  cardGrid: {
    marginTop: "10px",
  },
  bannerPaper: {
    borderRadius: 0,
    height: '100%',
    width: '100%',
    background: ` linear-gradient(
      rgb(197 192 192 / 7%), 
      rgb(0 0 0 / 28%)
    ),url(${banner22})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    padding: 34,
    paddingTop: 60
  },
  text: {
    color: "#827e7e",
    paddingLeft: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    }
  },
  imagelogo: {
    width: "32px",
    height: "32px",

  },
  text2: {
    fontSize: "20px",
    color: "#2979ff",

  },
  icon: {
    width: "1.8em",
    height: "2em",
    // [theme.breakpoints.down("xs")]:{
    //   width: "1em",
    // }
  },
  gridmobpad: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledPaper = styled(Box)(({ theme, isMatch }) => ({
  width: '55px',
  height: isMatch ? '100px' : '80px',
  background: '#024e6d',
  position: 'relative',
  overflow: 'hidden',
  top: isMatch ? '-3em' : '-2em',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '20%',
    background: 'white',
  },
  '&::before': {
    left: 0,
    transform: 'skewY(-30deg)',
    transformOrigin: 'bottom right',
  },
  '&::after': {
    right: 0,
    transform: 'skewY(30deg)',
    transformOrigin: 'bottom left',
  },
}))

const StyledDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '-57px',
  left: '0',
  right: '0',
  width: '100%',
  height: '110px',
  background: 'white',
  clipPath: 'polygon(0 50%, 25% 100%, 50% 50%, 75% 100%, 100% 50%, 50% 0)',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  borderRadius: 'none',
}))
const StyledButton = styled(Button)(({ theme, isMatch }) => ({
  width: isMatch ? '19em' : '21em',
  height: isMatch ? 38 : 40,
  borderRadius: 50,
  fontSize: isMatch ? 15 : '1.1em',// Set the border color to white
  backgroundColor: 'rgb(13, 118, 214)',
  color: '#fff',// Set the background color to transparent
  '&:hover': {
    backgroundColor: 'rgb(13, 118, 214)',
    color: '#fff', // Set the background color to white on hover
    transform: 'scale(1.05)',
  },
}))



const ContactUS = () => {
  const classes = useStyles();
  const context = React.useContext(PropertyContext);
  const { testimonials, TestimonialData } = context;
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  let ZillowReviews = testimonials;

  ZillowReviews = [
    ...ZillowReviews.filter(reviews => reviews.platform !== "Facebook")
  ]
  // const ZillowReviews = [
  //   ...testimonials.filter(reviews => reviews.platform === "Zillow")
  // ]

  const handleClick = () => {
    setOpen(true);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleClick1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleChange = e => {
    // var {name, value} = e.target
    // setValues({
    //     ...values,
    //     [name]: value,

    // });
  }

  const handleEmailSubmit = e => {
    e.preventDefault();

    setLoading(true)
    emailjs.sendForm(
      'service_b9v5hfs',
      'template_m7yxtzs',
      e.target,
      'user_c6L1p3GVRkDc5i4AlvRat')
      .then((result) => {
        console.log(result.text);
        handleClick(Alert);
        setLoading(false)
      }, (error) => {
        console.log(error.text);
        handleClickError(AlertError);
        setLoading(false)
      });

    e.target.reset()
  }

  React.useEffect(() => {
    if (ZillowReviews.length === 0) {
      TestimonialData()
    }
  }, [])

  if (ZillowReviews.length === 0) {
    return <Loading />
  }


  return (
    <div>
      <Box style={{ margin: 0, background: "white" }} >
        <Paper className={classes.bannerPaper}>
          <Container className={classes.cardGrid} maxWidth="md">
            <div className="BAnner_text_Nav">
              <h3>Contact us </h3>
            </div>
          </Container>
        </Paper>
        {
          isMatch ?
            <Container maxWidth="md">
              <Grid container spacing={isMatch ? 0 : 3} style={{ alignItems: "center", paddingBottom: "30px" }} >
                <Grid item xs={12} sm={12} md={6}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card className="container" elevation={5} rounded >
                      {/*  */}
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <Typography sx={{ fontSize: '1.5em', fontWeight: 500 }}>Book a consultation call with Deepti today!!</Typography>

                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                          <StyledPaper isMatch={isMatch}>
                            <img width="50px" height="50px" src={kelly} alt="HomesWaState" style={{ margin: '1.2em 0em' }} />
                          </StyledPaper>
                        </Grid>
                      </Grid>
                      <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1} sx={{ mt: isMatch ? '1em' : '0em', bgcolor: '#e0e0e0', p: '1em' }}>
                        <Avatar
                          alt="Remy Sharp"
                          src={Image}
                          sx={{ width: 160, height: 170 }}
                        />
                        <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                          <Typography sx={{ fontSize: isMatch ? '1.5em' : '1.6em', fontWeight: 500, }}>Deepti Gupta</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Real Estate Agent</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Direct : 425-588-8470</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Office : 425-877-8290</Typography>
                          <Rating name="read-only" value="5" readOnly />
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 500, }}>5.0 out of 5 stars</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, }}>
                            <Link href="https://www.zillow.com/profile/Deepti-Gupta/" underline='none' target="_blank" >
                              114 Zillow Reviews
                            </Link>
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={isMatch ? 5 : 3} sx={{ p: isMatch ? '2em' : '3em 5em 2em 4em' }}>
                          <StyledButton variant='contained' startIcon={<PhoneIphone />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} href="tel:425-588-8470">Call Me</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<Mail />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} target="_blank"
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">Email</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<WhatsAppIcon fontSize='large' />} isMatch={isMatch}>
                            <Link
                              style={{ textDecoration: 'none', color: '#fff' }}
                              href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)"
                            >
                              WhatsApp me
                            </Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            onClick={handleClick1}
                            startIcon={<Forum />} isMatch={isMatch}>
                            Send Message
                          </StyledButton>

                        </Stack>
                        <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title" scroll="paper" sx={{ '&.MuiDialog-paper': { overflow: 'hidden' } }}>
                          <DialogTitle>
                            Book a consultation call with Deepti today!!
                            <IconButton
                              aria-label="close"
                              onClick={handleClose1}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <Close />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <form
                              onSubmit={handleEmailSubmit}>
                              <input type="hidden" name='heading' value="Contact Us page" />
                              <Grid container spacing={2} style={{ padding: '2em' }}>
                                <Grid item xs={12} >
                                  <TextField
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className="text_contact"
                                    // value={values.email}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    className="text_contact"
                                    // value={values.name}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    label="Number"
                                    name="number"
                                    type="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    // value={values.number}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"

                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    autoComplete="message"
                                    className="text_contact"
                                    // value={values.message}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={loading}
                                  // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                  >
                                    Send
                                  </Button>
                                </Grid>
                              </Grid>
                              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                  Thankyou for writing to us! We have received your message and we will contact you soon.
                                </Alert>
                              </Snackbar>
                              <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                  Please try again!
                                </Alert>
                              </Snackbar>
                            </form >
                          </DialogContent>
                        </Dialog>
                      </Stack>
                    </Card>
                  </Container>
                  {/* <Divider orientation="horizantal" variant="middle" sx={{m: '4em 1em'}}/> */}
                </Grid>
                
                <Grid item xs={12} sm={12} md={6} className={classes.center} style={{ marginBottom: isMatch ? '0em' : '14em' }}>
                  <Container component="main" maxWidth="xs" className={classes.gridmobpad}>
                    <Grid item xs={12} >
                      <Typography sx={{ fontSize: "20px", color: "#2979ff", }}>
                        Connect us on social media
                      </Typography>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">
                          <ButtonBase disableRipple>
                            <PhoneIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                          <ButtonBase disableRipple>
                            <FacebookIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                          <ButtonBase disableRipple>
                            <WhatsAppIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6}  alignItems="center">
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                          <ButtonBase disableRipple>
                            <Instagram color="primary" sx={{
                              width: "1.8em",
                              height: "2em",
                            }} />
                            <Typography className={classes.text} >
                              deeptigupta_realestate
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.zillow.com/profile/Deepti-Gupta/">
                          <ButtonBase disableRipple>
                            <img src={Zillow} alt="Zillow" className={classes.imagelogo} />
                            <Typography className={classes.text}>
                              Deepti-Gupta
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank"
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                          <ButtonBase disableRipple>
                            <EmailIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </Container>
            :
            <Container maxWidth="md">
              <Grid container spacing={isMatch ? 0 : 3} style={{ alignItems: "center", paddingBottom: "30px" }} >
                <Grid item xs={12} sm={12} md={6} className={classes.center} style={{ marginBottom: isMatch ? '0em' : '14em' }}>
                  <Container component="main" maxWidth="xs" className={classes.gridmobpad}>
                    <Grid item xs={12} >
                      <Typography sx={{ fontSize: "20px", color: "#2979ff", }}>
                        Connect us on social media
                      </Typography>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-588-8470">
                          <ButtonBase disableRipple>
                            <PhoneIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq">
                          <ButtonBase disableRipple>
                            <FacebookIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)">
                          <ButtonBase disableRipple>
                            <WhatsAppIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              425-588-8470
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.instagram.com/deeptigupta_realestate/">
                          <ButtonBase disableRipple>
                            <Instagram color="primary" sx={{
                              width: "1.8em",
                              height: "2em",
                            }} />
                            <Typography className={classes.text}>
                              deeptigupta_realestate
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ padding: "30px 0px" }}>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank" href="https://www.zillow.com/profile/Deepti-Gupta/">
                          <ButtonBase disableRipple>
                            <img src={Zillow} alt="Zillow" className={classes.imagelogo} />
                            <Typography className={classes.text}>
                              Deepti-Gupta
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                      <Grid item xs={6} >
                        <Link color="primary" style={{ textDecoration: 'none' }} target="_blank"
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">
                          <ButtonBase disableRipple>
                            <EmailIcon color="primary" className={classes.icon} />
                            <Typography className={classes.text}>
                              dguptahomes
                            </Typography>
                          </ButtonBase>
                        </Link>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>



                <Grid item xs={12} sm={12} md={6}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card className="container" elevation={5} rounded >
                      {/*  */}
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <Typography sx={{ fontSize: '1.5em', fontWeight: 500 }}>Book a consultation call with Deepti today!!</Typography>

                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                          <StyledPaper isMatch={isMatch}>
                            <img width="50px" height="50px" src={kelly} alt="HomesWaState" style={{ margin: '1.2em 0em' }} />
                          </StyledPaper>
                        </Grid>
                      </Grid>
                      <Stack direction={isMatch ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1} sx={{ mt: isMatch ? '1em' : '0em', bgcolor: '#e0e0e0', p: '1em' }}>
                        <Avatar
                          alt="Remy Sharp"
                          src={Image}
                          sx={{ width: 160, height: 170 }}
                        />
                        <Stack direction="column" justifyContent={isMatch ? "center" : null} alignItems={isMatch ? "center" : null} sx={{ pt: isMatch ? 0 : '1em' }} spacing={0.3}>
                          <Typography sx={{ fontSize: isMatch ? '1.5em' : '1.6em', fontWeight: 500, }}>Deepti Gupta</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Real Estate Agent</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Direct : 425-588-8470</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, textTransform: 'uppercase' }}>Office : 425-877-8290</Typography>
                          <Rating name="read-only" value="5" readOnly />
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 500, }}>5.0 out of 5 stars</Typography>
                          <Typography sx={{ fontSize: isMatch ? '15px' : '1em', fontWeight: 400, }}>
                            <Link href="https://www.zillow.com/profile/Deepti-Gupta/" underline='none' target="_blank" >
                              129 Zillow Reviews
                            </Link>
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={isMatch ? 5 : 3} sx={{ p: isMatch ? '2em' : '3em 5em 2em 4em' }}>
                          <StyledButton variant='contained' startIcon={<PhoneIphone />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} href="tel:425-588-8470">Call Me</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<Mail />} isMatch={isMatch}>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} target="_blank"
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=dguptahomes@gmail.com&su=HomeWAstate_Contact">Email</Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            startIcon={<WhatsAppIcon fontSize='large' />} isMatch={isMatch}>
                            <Link
                              style={{ textDecoration: 'none', color: '#fff' }}
                              href="https://api.whatsapp.com/send?phone=+14255888470&text=Subject: HomeWaState(Contact for More Details)"
                            >
                              WhatsApp me
                            </Link>
                          </StyledButton>
                          <StyledButton
                            variant='contained'
                            color="primary"
                            onClick={handleClick1}
                            startIcon={<Forum />} isMatch={isMatch}>
                            Send Message
                          </StyledButton>

                        </Stack>
                        <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title" scroll="paper" sx={{ '&.MuiDialog-paper': { overflow: 'hidden' } }}>
                          <DialogTitle>
                            Book a consultation call With Deepti today!!
                            <IconButton
                              aria-label="close"
                              onClick={handleClose1}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <Close />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <form
                              onSubmit={handleEmailSubmit}>
                              <input type="hidden" name='heading' value="Contact Us page" />
                              <Grid container spacing={2} style={{ padding: '2em' }}>
                                <Grid item xs={12} >
                                  <TextField
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className="text_contact"
                                    // value={values.email}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    className="text_contact"
                                    // value={values.name}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    label="Number"
                                    name="number"
                                    type="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    // value={values.number}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <TextField
                                    variant="outlined"
                                    margin="normal"

                                    fullWidth
                                    id="outlined-multiline-static"
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    autoComplete="message"
                                    className="text_contact"
                                    // value={values.message}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={12} >
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={loading}
                                  // onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                  >
                                    Send
                                  </Button>
                                </Grid>
                              </Grid>
                              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                  Thankyou for writing to us! We have received your message and we will contact you soon.
                                </Alert>
                              </Snackbar>
                              <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error">
                                  Please try again!
                                </Alert>
                              </Snackbar>
                            </form >
                          </DialogContent>
                        </Dialog>
                      </Stack>


                    </Card>
                  </Container>
                </Grid>
              </Grid>
            </Container>
        }



        <Footer />
      </Box>






    </div >
  )
}
export default ContactUS;
















