import React, { useState, useEffect } from 'react';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logoS3 from '../images/logoS.3.png'
import kelly from '../images/kelly.jpeg'
import kelly2 from '../images/kelly2.png'
import firebase from '../firebase/firebase';
import { AppBar, Box, Button, Container, CssBaseline, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Slide, styled, Tab, Tabs, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { Apartment, AutoAwesomeMotionRounded, BookRounded, ContactPhone, DeckRounded, GroupRounded, HomeRounded, Star } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tab: {
    textTransform: "none",
    fontSize: " 1rem",
    fontWeight: 600,
    width: "auto",

  },
  // container: {
  //   background: "#184252"
  // },
  List: {
    width: 200,
  },
  ListItem: {
    paddingLeft: 10,
    paddingRight: 10
  },
  ListIcon: {
    // minWidth: 'none'
  },
  NavList: {
    display: "flex",
    overflowX: "auto",
    // background:" #2979ff"
  },
  tab2: {
    textTransform: "none",
    fontSize: " 1rem",
    fontWeight: 600,
    // width: "150px",
    height: 40,
    alignSelf: "center",

  },
  DrawerCSS: {
    marginLeft: "auto",
    "&:hover": {

    }
  },
  toolbar: {
    marginLeft: "auto"
  },
  logoText: {
    color: "white",
    fontSize: "15px",
    fontWeight: 400
  },
  paper: {
    borderRadius: 4,
    marginTop: 10
  }
}));

const ScrollableTabsButtonAuto = styled(Tabs)(({ theme }) => ({
  overflowX: 'auto',
  '& .MuiTabs-flexContainer': {
    display: 'contents',
    '& .MuiTab-root': {
      minWidth: 0,
      marginRight: theme.spacing(1),
    },
  },

}));



const StyledTab = styled(Tab)(({ theme, user }) => ({
  textTransform: "none",
  fontSize: " 1rem",
  fontWeight: 600,
  width: "auto",
  minWidth: user ? 100 : 100,
  '&.Mui-selected': {
    color: '#000',
  },
}))


const StyledTab1 = styled(Tab)(({ theme, user }) => ({
  textTransform: "none",
  fontSize: " 1rem",
  fontWeight: 600,
  width: "auto",
  minWidth: user ? 120 : 130,
  '&.Mui-selected': {
    color: '#000',
  },
}))

const StyledButton = styled(Button)(({ theme, user }) => ({
  textTransform: "none",
  fontSize: " 1rem",
  fontWeight: 600,
  height: 40,
  alignSelf: "center",
}))

const StyledButton1 = styled(Button)(({ theme, user }) => ({
  textTransform: "none",
  fontSize: " 1rem",
  fontWeight: 600,
  height: 40,
  alignSelf: "center",
  minWidth: 80,
  marginRight: 10,
  marginTop: 4
}))


// function ElevationScroll(props) {
//   const { children, window } = props;

//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 4,
//   });
// }
const HideOnScroll = ({ children }) => {
  const [showSecondaryNavbar, setShowSecondaryNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThresholdEm = 90; // Set the scroll threshold in em units
      const scrollThresholdPx = scrollThresholdEm * 16; // Convert em to pixels

      if (window.scrollY > scrollThresholdPx) {
        setShowSecondaryNavbar(true);
      } else {
        setShowSecondaryNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Slide appear={false} direction="down" in={!showSecondaryNavbar}>
        {children}
      </Slide>
    </React.Fragment>
  );
};


function Navbar(props) {
  const [user, setUser] = useState(false)
  // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const menuOpen = Boolean(anchorEl);
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  // const [open1, setOpen1] = useState(false);
  // const [openList, setOpenList] = useState(true);
  const [value, setValue] = useState(0);
  const match1 = window.location.pathname.split('/')[1] === 'Project-Alexander61' ? true : false;


  // function Logout() {
  //   firebase.auth().signOut();
  // }
  useEffect(() => {

    var user = firebase.auth().currentUser;
    if (user !== null) {
      setUser(true);
    }
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('This is the user: ', user)
        setUser(true);
      }
    }
    )

    if (window.location.pathname === "/" && value !== 0) {
      setValue(0)
    } else
      if (window.location.pathname === "/New-constructions" && value !== 1) {
        setValue(1)
      } else
        if (window.location.pathname === "/Reviews" && value !== 2) {
          setValue(2)
        } else
          if (window.location.pathname === "/Last-Solds" && value !== 3) {
            setValue(3)
          } else
            if (window.location.pathname === "/Our-Listings" && value !== 4) {
              setValue(4)
            } else
              if (window.location.pathname === "/Staged-to-Sell" && value !== 5) {
                setValue(5)
              } else
                if (window.location.pathname === "/Meet-Deepti" && value !== 6) {
                  setValue(6)
                } else
                  if (window.location.pathname === "/Blog" && value !== 7) {
                    setValue(7)
                  } else
                    if (window.location.pathname === "/Buyer-Information" && value !== 8) {
                      setValue(8)
                    } else
                      if (window.location.pathname === "/Seller-Information" && value !== 9) {
                        setValue(9)
                      }
                      else
                        if (window.location.pathname === "/Rental-Information" && value !== 10) {
                          setValue(10)
                        }
                        else
                          if (window.location.pathname === "/Project-Alexander61" && value !== 11) {
                            setValue(11)
                          }
                          else
                            if (window.location.pathname === "/Deals" && value !== 12) {
                              setValue(12)
                            }
                            else
                              if (window.location.pathname === "/Self-Service" && value !== 13) {
                                setValue(13)
                              }
                              else
                                if (window.location.pathname === "/Contact-US-Tiranga" && value !== 13) {
                                  setValue(14)
                                }
                                else
                                  if (window.location.pathname === "/Contact-US" && value !== 14) {
                                    setValue(15)
                                  }

  }, []);
  // console.log("user", user)

  // const handleClick1 = () => {
  //   setAnchorEl(null);
  //   setOpen1(true);
  // };

  const handleChange = (e, value) => {
    setValue(value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleClose1 = () => {
  //   setOpen1(false);
  // };

  function Logout() {
    firebase.auth().signOut();
    setUser(false)
  }
  const tabs = (
    <>
      <Tabs value={value} onChange={handleChange} indicatorColor="none" >
        <StyledTab label="Home" component={Link} user={user} to="/" />
        {/* <StyledTab1 label="New Constructions" component={Link} user={user} to="/New-constructions" /> */}
        <StyledTab1 label="New Constructions" component={Link} user={user} to="/New-constructions" />
        <StyledTab label="Reviews" component={Link} to="/Reviews" user={user} />
        <StyledTab1 label="Last Solds" component={Link} to="/Last-Solds" />
        {/* <StyledTab1 label="Featured Listings"  component={Link} to="/FeaturedListings" /> */}
        <StyledTab1 label="Our Listings" component={Link} to="/Our-Listings" />
        <StyledTab1 label="Staged to Sell" component={Link} to="/Staged-to-Sell" />
        {/* <StyledTab1 label="Availability"  component={Link} to="/Availability" /> */}
        <StyledTab label="Meet Deepti" component={Link} to="/Meet-Deepti" />
        <div>
          <StyledButton1 aria-controls="fade-menu" aria-haspopup="true" color="primary" onClick={handleClick}
            endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
          >
            Others
          </StyledButton1>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
          >
            <MenuItem onClick={handleClose} component={Link} to="/Blog">Blog</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/Buyer-Information">Buyer Information</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/Seller-Information">Seller Information</MenuItem>
            {/* <MenuItem onClick={handleClose} component={Link} to="/Rental-Information">Rental Information</MenuItem> */}
            <MenuItem onClick={handleClose} component={Link} to="/Project-Alexander61">Project Alexander61</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/Deals">Deals</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/Contact-US-Tiranga">Contact US - Tiranga</MenuItem>
            {/* <MenuItem onClick={handleClose} component={Link} to="/Our-Details">Listings </MenuItem> */}
            {user ? <MenuItem onClick={handleClose} component={Link} to="/Self-Service">Self Service</MenuItem> : null}
            {/* {user ?
                  <div>
                    <MenuItem onClick={handleClose} component={Link} to="/Contact-US">Contact Us</MenuItem>
                    <MenuItem onClick={handleClick1}>
                      <ListItemIcon>
                        <WhatsAppIcon sx={{ color: '#4dc247' }} />
                      </ListItemIcon>
                      WhatsApp
                    </MenuItem>
                    <WhatsappDailog Open={open1} Close={handleClose1} />
                  </div>
                  : null
                } */}
          </Menu>
        </div>
        {user ?
          <StyledButton variant="contained" component={Link} to="/" onClick={Logout}
            sx={{
              background: '#f50057',
              '&:hover': {
                background: '#f50057'
              }
            }}
          >
            Log Out
          </StyledButton>
          :
          <StyledButton component={Link} to="/Contact-US" variant="contained" color="primary" size='small' fullWidth>
            Contact Us
          </StyledButton>}
      </Tabs>
    </>
  )

  // const Drawer = (
  //   <>
  //     <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS} open={open} onClose={() =>
  //       setOpen(false)} onOpen={() => setOpen(true)}>
  //       <div className={classes.root}>
  //         <List component="nav" aria-label="main mailbox folders" className={classes.List}>
  //           <ListItem button onClick={() => setOpen(false)} component={Link} to="/">
  //             <ListItemIcon >
  //               <HomeTwoTone />
  //             </ListItemIcon>
  //             <ListItemText primary="Home" />
  //           </ListItem>
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="/Reviews">
  //             <ListItemIcon >
  //               <GroupAddTwoTone />
  //             </ListItemIcon>
  //             <ListItemText primary="Reviews" />
  //           </ListItem>
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="/Soldsofar">
  //             <ListItemIcon >
  //               <Star />
  //             </ListItemIcon>
  //             <ListItemText primary="Sold So Far" />
  //           </ListItem>
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="FeaturedListings">
  //             <ListItemIcon >
  //               <Dns />
  //             </ListItemIcon>
  //             <ListItemText primary="Featured Listings" />
  //           </ListItem>
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="/ListingsbyDeepti">
  //             <ListItemIcon >
  //               <AutoAwesomeMotionRounded />
  //             </ListItemIcon>
  //             <ListItemText primary="Listings By Deepti" />
  //           </ListItem>
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="/StagingsbyDeepti">
  //             <ListItemIcon >
  //               <DeckRounded/>
  //             </ListItemIcon>
  //             <ListItemText primary="Stagings By Deepti" />
  //           </ListItem>
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="/Availability">
  //             <ListItemIcon >
  //               <AssignmentTurnedIn />
  //             </ListItemIcon>
  //             <ListItemText primary="Availability" />
  //           </ListItem>
  //           <ListItem button onClick={handleClick} component={Link} to="/Blog">
  //             <ListItemIcon>
  //               <BookRounded />
  //             </ListItemIcon>
  //             {anchorEl ? <ExpandLess /> : <ExpandMore />}
  //             <ListItemText primary="Blog" />
  //           </ListItem>
  //           <Menu
  //             id="fade-menu"
  //             anchorEl={anchorEl}
  //             anchorOrigin={{
  //               vertical: "bottom",
  //               horizontal: "center"
  //             }}
  //             transformOrigin={{
  //               vertical: "top",
  //               horizontal: "center"
  //             }}
  //             keepMounted
  //             open={Boolean(anchorEl)}
  //             onClose={handleClose}
  //             classes={{ paper: classes.paper }}
  //           >
  //             <MenuItem onClick={handleClose} component={Link} to="/Blog">Blog</MenuItem>
  //             <MenuItem onClick={handleClose} component={Link} to="/Buyer-Information">Buyer Information</MenuItem>
  //             <MenuItem onClick={handleClose} component={Link} to="/Seller-Information">Seller Information</MenuItem>
  //           </Menu>
  //           {/* <ListItem  onClick={() => setOpen(false)} button component={Link} to="/selling-options">
  //             <ListItemIcon >
  //               <DeckRoundedIcon/>
  //             </ListItemIcon>
  //             <ListItemText primary="Selling Option" />
  //           </ListItem> */}
  //           <ListItem onClick={() => setOpen(false)} button component={Link} to="/Contact-US">
  //             <ListItemIcon >
  //               <ContactPhoneIcon />
  //             </ListItemIcon>
  //             <ListItemText primary="Contact Us" />
  //           </ListItem>
  //         </List>
  //         <Divider />

  //       </div>
  //     </SwipeableDrawer>
  //     <IconButton onClick={() => setOpen(!open)} className={classes.DrawerCSS}>
  //       <MenuIcon />
  //     </IconButton>
  //   </>
  // )

  const Nav = (
    <ScrollableTabsButtonAuto
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons
      indicatorColor="none"
      aria-label="scrollable auto tabs example"
    >
      <List component="nav" aria-label="main mailbox folders" sx={{ display: "flex", overflowX: "auto", }}>
        <ListItem button component={Link} to="/" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <HomeRounded sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        {/* <ListItem button onClick={() => setOpen(false)} component={Link} to="/New-constructions" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <MapsHomeWorkRounded sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="New Constructions" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem> */}
        <ListItem button component={Link} to="/New-constructions" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Apartment sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="New Constructions" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        <ListItem button component={Link} to="/Reviews" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <GroupRounded sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Reviews" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        <ListItem button component={Link} to="/Last-Solds" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Star sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Last Solds" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        {/* <ListItem onClick={() => setOpen(false)} button component={Link} to="/FeaturedListings" className={classes.ListItem}>
          <ListItemIcon className={classes.ListIcon}>
            <Dns sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Featured Listings" sx={{ whiteSpace: "nowrap" }} />
        </ListItem> */}
        <ListItem button component={Link} to="/Our-Listings" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <AutoAwesomeMotionRounded sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Our Listings" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        <ListItem button component={Link} to="/Staged-to-Sell" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <DeckRounded sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Staged to Sell" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        {/* <ListItem onClick={() => setOpen(false)} button component={Link} to="/Availability" className={classes.ListItem}>
          <ListItemIcon className={classes.ListIcon}>
            <AssignmentTurnedInIcon style={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Availability" style={{ whiteSpace: "nowrap" }} />
        </ListItem> */}
        <ListItem button component={Link} to="/Meet-Deepti" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <InfoIcon sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Meet Deepti" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
        <ListItem button className={classes.ListItem} onClick={handleClick}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <BookRounded sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Others" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
          {anchorEl ? <ExpandLess sx={{ whiteSpace: "nowrap" }} /> : <ExpandMore sx={{ whiteSpace: "nowrap" }} />}
        </ListItem>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
        >
          <MenuItem onClick={handleClose} component={Link} to="/Blog">Blog</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/Buyer-Information">Buyer Information</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/Seller-Information">Seller Information</MenuItem>
          {/* <MenuItem onClick={handleClose} component={Link} to="/Rental-Information">Rental Information</MenuItem> */}
          <MenuItem onClick={handleClose} component={Link} to="/Project-Alexander61">Project Alexander61</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/Deals">Deals</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/Contact-US-Tiranga">Contact US - Tiranga</MenuItem>
          {user ? <MenuItem onClick={handleClose} component={Link} to="/Self-Service">Self Service</MenuItem> : null}
          {/* <ListItem onClick={() => setOpen(false)} button component={Link} to="/selling-options" className={classes.ListItem}>
          <ListItemIcon className={classes.ListIcon}>
            <DeckRoundedIcon style={{ whiteSpace: "nowrap" }} />
          </ListItemIcon>
          <ListItemText primary="Selling Option" style={{ whiteSpace: "nowrap" }} />
        </ListItem> */}
          {/* {user ?
            <div>
              <MenuItem onClick={handleClose} component={Link} to="/Contact-US">Contact Us</MenuItem>
              <MenuItem onClick={handleClick1}>
                <ListItemIcon>
                  <WhatsAppIcon sx={{ color: '#4dc247' }} />
                </ListItemIcon>
                WhatsApp
              </MenuItem>
              <WhatsappDailog Open={open1} Close={handleClose1} />
            </div>
            : null
          } */}
        </Menu>
        <ListItem button component={Link} to="/Contact-US" sx={{ p: '0px 10px', }}>
          <ListItemIcon sx={{ minWidth: 0 }}>
            <ContactPhone sx={{ whiteSpace: "nowrap" }} />
          </ListItemIcon >
          <ListItemText primary="Contact Us" sx={{ pl: '10px', whiteSpace: "nowrap" }} />
        </ListItem>
      </List>
    </ScrollableTabsButtonAuto>
  )
  return (
    <>
      {
        (match1 === true) ?
          <HideOnScroll>
            <div >
              <CssBaseline />
              <AppBar color="inherit" position="fixed">
                <Container maxWidth="xl" sx={{ background: matches ? "#184252" : null }}>
                  <Toolbar sx={{ justifyContent: matches ? "unset" : "center" }} >
                    <Link to='/' style={{ textDecoration: 'none' }}>
                      {matches ?
                        <Typography sx={{ color: "white", fontSize: "15px", fontWeight: 400 }}>
                          <img className="header_icon_mob" src={logoS3} alt="HomesWaState" />
                          <img className="header_icon_mob1" src={kelly2} alt="HomesWaState" />
                          {user && <Button variant="outlined" sx={{ color: "white", borderColor: "white", border: "2px solid" }} onClick={Logout} >
                            Log Out
                          </Button>}
                        </Typography> :
                        <img className="header_icon_desk" src={kelly} alt="HomesWaState" />
                      }
                    </Link>
                    {matches ? null : tabs}
                  </Toolbar>
                </Container>
                {matches ? <Box sx={{ bgcolor: 'background.paper', overflow: 'auto', maxHeight: 200 }}>{matches ? Nav : null}</Box> : null}
              </AppBar>
              <Toolbar />
            </div>
          </HideOnScroll>
          :
          <div >
            <CssBaseline />
            <AppBar color="inherit" position="fixed">
              <Container maxWidth="xl" sx={{ background: matches ? "#184252" : null }}>
                <Toolbar sx={{ justifyContent: matches ? "unset" : "center" }} >
                  <Link to='/' style={{ textDecoration: 'none' }}>
                    {matches ?
                      <Typography sx={{ color: "white", fontSize: "15px", fontWeight: 400 }}>
                        <img className="header_icon_mob" src={logoS3} alt="HomesWaState" />
                        <img className="header_icon_mob1" src={kelly2} alt="HomesWaState" />
                        {user && <Button variant="outlined" sx={{ color: "white", borderColor: "white", border: "2px solid" }} onClick={Logout} >
                          Log Out
                        </Button>}
                      </Typography> :
                      <img className="header_icon_desk" src={kelly} alt="HomesWaState" />
                    }
                  </Link>
                  {matches ? null : tabs}
                </Toolbar>
              </Container>
              {matches ? <Box sx={{ bgcolor: 'background.paper', overflow: 'auto', maxHeight: 200 }}>{matches ? Nav : null}</Box> : null}
            </AppBar>
            <Toolbar />
          </div>

      }
    </>
  );
}

export default Navbar;









